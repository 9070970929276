import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/projects",
    component: lazy(() => import("../Container/projects")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-detail/:id",
    component: lazy(() => import("../Container/project-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/projects-edit/:id",
    component: lazy(() => import("../Container/project-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-details/:ids/task-edit/:id",
    component: lazy(() => import("../Container/task-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/upload-customer-purchase-order/:id",
    component: lazy(() =>
      import("../Container/upload-customer-purchase-order")
    ),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/release-vendor-purchase-order/:id",
    component: lazy(() => import("../Container/release-vendor-purchase-order")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/generate-customer-shipping/:id",
    component: lazy(() => import("../Container/generate-customer-shipping")),
    meta: {
      authRoute: true,
    },
  },

  {
    path: "/add-receipt",
    component: lazy(() => import("../Container/add-receipt")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/update-invoice-vendor/:id",
    component: lazy(() => import("../Container/update-invoice-vendor")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/generate-invoice-customer/:id",
    component: lazy(() => import("../Container/generate-invoice-customer")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/add-expenses",
    component: lazy(() => import("../Container/add-expenses")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/pdfviewer",
    component: lazy(() => import("../Container/pdfviewer")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/update-vendor-shipping/:id",
    component: lazy(() => import("../Container/update-vendor-shipping")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/commercial-delivery/:projectId/:id",
    component: lazy(() => import("../Container/commercial-delivery")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-commercial-delivery/:projectId/:id",
    component: lazy(() => import("../Container/vendor-commercial-delivery")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/customer-purchase-order/:id",
    component: lazy(() => import("../Container/customer-purchase-order")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/purchase-order/:id",
    component: lazy(() => import("../Container/purchase-order")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/tax-invoice/:id",
    component: lazy(() => import("../Container/tax-invoice")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-tax-invoice/:id",
    component: lazy(() => import("../Container/vendor-tax-invoice")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/quotation/:id",
    component: lazy(() => import("../Container/quotation")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/generate-delivery-order/:id/:shippingId",
    component: lazy(() => import("../Container/generate-delivery-order")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
