import { lazy } from 'react'

const DashboardRoute = [

  {
    path: '/users',
    component: lazy(() => import('../Container/users')),
    meta: {
      authRoute: true
    }
  },

  {
    path: '/users-add',
    component: lazy(() => import('../Container/createForm')),
    meta: {
      authRoute: true
    }
  },

  {
    path: '/users-edit/:id',
    component: lazy(() => import('../Container/users-edit')),
    meta: {
      authRoute: true
    }
  },

]

export default DashboardRoute