import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/leads",
    component: lazy(() => import("../Container/leads")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-detail/:uuid",
    component: lazy(() => import("../Container/lead-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-edit/:id",
    component: lazy(() => import("../Container/lead-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-add",
    component: lazy(() => import("../Container/lead-add")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/create-vendor-enquiry",
    component: lazy(() => import("../Container/create-vendor-enquiry")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/add-vendor-proposal",
    component: lazy(() => import("../Container/add-vendor-proposal")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/add-new-proposal",
    component: lazy(() => import("../Container/add-new-proposal")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/compare-vendor-proposal",
    component: lazy(() => import("../Container/compare-vendor-proposal")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/view-vendor-proposal",
    component: lazy(() => import("../Container/view-vendor-proposal")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
