import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/deal-detail",
    component: lazy(() => import("../Container/deal-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/deal-add",
    component: lazy(() => import("../Container/deal-add")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/deal-info/:id",
    component: lazy(() => import("../Container/deal-info")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/deal-edit/:id",
    component: lazy(() => import("../Container/deal-edit")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
