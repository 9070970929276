import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/sales",
    component: lazy(() => import("../Container/sales")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/sales-proposals/:id",
    component: lazy(() => import("../Container/sales-proposals")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/sales-invoice",
    component: lazy(() => import("../Container/sales-invoice")),
    meta: {
      authRoute: true,
    },
  },

  {
    path: "/proposals",
    component: lazy(() => import("../Container/proposals")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/proposal-edit/:id",
    component: lazy(() => import("../Container/proposal-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/proposal-add",
    component: lazy(() => import("../Container/proposal-add-deal")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/pending-proposals",
    component: lazy(() => import("../Container/pending-proposals")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
