import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/contact",
    component: lazy(() => import("../Container/contact")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/contact-detail/:id",
    component: lazy(() => import("../Container/contact-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/contact-edit/:id",
    component: lazy(() => import("../Container/contact-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/contact-add",
    component: lazy(() => import("../Container/contact-deal-add")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
