import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/customers",
    component: lazy(() => import("../Container/customers")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/customers-detail/:id",
    component: lazy(() => import("../Container/customers-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/customers-edit/:id",
    component: lazy(() => import("../Container/customers-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/customer-add",
    component: lazy(() => import("../Container/customer-add")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/customer-deal-add",
    component: lazy(() => import("../Container/customers-deal-add")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
