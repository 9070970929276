// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store, store2 } from './template/redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './template/configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './template/utility/context/Can'
import { ThemeContext } from './template/utility/context/ThemeColors'
import { Internationalization } from './services/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './template/@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './template/@core/components/ripple-button'

// ** Fake Database
import './template/@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './template/@core/assets/fonts/feather/iconfont.css'
import './template/@core/scss/core.scss'
import './template/assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { HelmetProvider } from "react-helmet-async";
// ** Lazy load app
const LazyApp = lazy(() => import('./App'))



ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}  >

  
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <Internationalization>
                <LazyApp />
                <ToastContainer newestOnTop />
              </Internationalization>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
  

    </Provider >

  </HelmetProvider >,
  document.getElementById('root')

)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()